import { render, staticRenderFns } from "./SectionHero.vue?vue&type=template&id=347df8b2&"
import script from "./SectionHero.vue?vue&type=script&lang=js&"
export * from "./SectionHero.vue?vue&type=script&lang=js&"
import style0 from "./SectionHero.vue?vue&type=style&index=0&id=347df8b2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/SectionHero.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fsections%2Flanding%2FSectionHero.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomButtonLink: require('/app/components/atoms/common/AtomButtonLink.vue').default,AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default,OrganismsPortfolioOrganismProjectSlider: require('/app/components/organisms/portfolio/OrganismProjectSlider.vue').default})
